import { t } from '@lingui/macro';

import routes from './src/appRoutes';

export const getNavItems = () => [
  {
    items: [
      {
        title: t`Print labels`,
        items: [
          {
            path: routes.home.getPath(),
            title: t`Tire hotel route`
          },
          {
            path: routes.printLabelsLicencePlateNumber.getPath(),
            title: t`License plate search`
          }
        ]
      }
    ]
  },
  {
    items: [
      {
        path: routes.globalPickingList.getPath(),
        title: t`Warehouse picking list`
      }
    ]
  },

  {
    items: [
      {
        path: routes.storageUnits.getPath(),
        title: t`Storage units`
      }
    ]
  }
];
