import { Typography } from '@mui/material';

interface ForbiddenPageProps {
  translations: {
    forbiddenAccess: string;
    youDoNotHavePermission: string;
  };
}

const ForbiddenPage = ({ translations }: ForbiddenPageProps) => {
  return (
    <div style={{ display: 'grid', placeContent: 'center', height: '100vh', textAlign: 'center' }}>
      <Typography variant='h1'>{translations.forbiddenAccess}</Typography>
      <Typography variant='subtitle1'>{translations.youDoNotHavePermission}</Typography>
    </div>
  );
};

// expect that all apps have same url to forbidden page
const forbiddenPageUrl = '/forbidden';

export { ForbiddenPage, forbiddenPageUrl };
