import { t } from '@lingui/macro';
import { Form, Formik } from 'formik';
import { URLKeys, noddiAsync } from 'noddi-async';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { useAuthContext } from 'noddi-provider';
import { useNoddiToast } from 'noddi-provider/src/utils/toast';
import {
  ApiErrorMessage,
  LoadingScreen,
  NoddiAsyncError,
  NoddiButton,
  NoddiFormTextInput,
  SimpleGridLayout
} from 'noddi-ui';
import * as Yup from 'yup';

const getValidationSchema = () =>
  Yup.object({
    firstName: Yup.string().required(t`Required`),
    lastName: Yup.string().required(t`Required`)
  });

export const Account = () => {
  const { userData } = useAuthContext();
  const { noddiToast } = useNoddiToast();

  const {
    data: user,
    error: userError,
    isPending: isUserPending
  } = noddiAsync.useGet({
    type: URLKeys.getUser,
    input: { id: userData?.user.id },
    queryConfig: {
      enabled: !!userData?.user.id
    }
  });

  const { mutateAsync: updateUser } = noddiAsync.usePatch({
    type: URLKeys.patchUser,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryExactMatch({ urlKey: URLKeys.getUser });
        noddiToast.success(t`Profile updated successfully`);
      },
      onError: async (error: NoddiAsyncError) => {
        noddiToast.error(error.message);
      }
    }
  });

  if (!userData || isUserPending) {
    return <LoadingScreen />;
  }

  if (userError) {
    return <ApiErrorMessage error={userError} />;
  }

  return (
    <Formik
      initialValues={{
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber
      }}
      enableReinitialize={true}
      validationSchema={getValidationSchema()}
      onSubmit={async (values) => {
        const valuesToSubmit = {
          id: user.id,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber
        };

        await updateUser({
          ...valuesToSubmit
        });
      }}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <div className='flex w-full flex-col gap-4'>
            <div className='rounded-2xl bg-primary-white px-5 py-4 text-primary-darkPurple shadow-md'>
              <SimpleGridLayout numColumns={2}>
                <NoddiFormTextInput name='firstName' label={t`First name`} />
                <NoddiFormTextInput name='lastName' label={t`Last name`} />
                <NoddiFormTextInput name='email' label={t`Email`} disabled={true} />
                <NoddiFormTextInput name='phoneNumber' label={t`Phone number`} disabled={true} />
              </SimpleGridLayout>
            </div>

            <div className='flex w-full justify-end'>
              <NoddiButton disabled={!isValid} loading={isSubmitting} type='submit'>
                {t`Save`}
              </NoddiButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
