import { useAuthContext } from 'noddi-provider';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const Logout = () => {
  const { logout } = useAuthContext();
  useEffect(() => {
    // Call logout in useEffect to avoid state update during render
    logout();
  }, [logout]);

  return null;
};

const PrivateLayout = () => {
  const { getToken, tokenLoginError } = useAuthContext();

  const token = getToken();

  if (tokenLoginError || !token) {
    if (tokenLoginError?.response?.status === 401) {
      return <Logout />;
    }
  }

  return <Outlet />;
};

export { PrivateLayout };
