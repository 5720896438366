import { t } from '@lingui/macro';
import { Account as AccountNoddiUi, NoddiContainer } from 'noddi-ui';

const Account = () => (
  <NoddiContainer header={t`My account`}>
    <AccountNoddiUi />
  </NoddiContainer>
);

export default Account;
