import { t } from '@lingui/macro';
import { NoddiContainer, PickingList } from 'noddi-ui';

// Fix styling later..

export const GlobalPickingList = () => {
  return (
    <NoddiContainer
      description={t`An overview of wheels that need to be picked up from the warehouse and loaded into cars prior to starting the route. Shows wheels for all routes.`}
      header={t`Warehouse picking list`}
    >
      <div className='mt-4'></div>
      <PickingList disablePersonalPickingList />
    </NoddiContainer>
  );
};
