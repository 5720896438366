import { t } from '@lingui/macro';
import { Stack } from '@mui/system';
import { useAuthContext } from 'noddi-provider';

import { colors } from '../../../../../tailwind-design-preset';
import { NoddiIcon } from '../../../../atoms/NoddiIcon';
import { SideNavDivider } from './SideNavDivider';
import { SideNavSection } from './SideNavSection';
import { VerticalLayoutProps } from './VerticalLayoutProps';

export const paths = {
  profile: '/profile',
  logout: '/logout'
};

export const getPageTitles = () => {
  return {
    profile: t`My account`,
    logout: t`Log out`
  };
};

const SideNavFooter = ({
  CustomNavElement,
  LanguagePicker,
  customPathToProfile,
  hideProfile
}: Pick<VerticalLayoutProps, 'CustomNavElement' | 'LanguagePicker' | 'customPathToProfile' | 'hideProfile'>) => {
  const { logout } = useAuthContext();
  const items = [];

  if (!hideProfile) {
    items.push({
      path: customPathToProfile ?? paths.profile,
      title: getPageTitles().profile,
      icon: <NoddiIcon color={colors.primary.white} size='medium' name='User' />
    });
  }

  items.push({
    path: paths.logout,
    onClick: logout,
    title: getPageTitles().logout,
    icon: <NoddiIcon name='Logout' size='medium' color={colors.primary.white} />
  });

  const navItems = [
    {
      items
    }
  ];

  return (
    <Stack sx={{ marginBottom: '30px' }}>
      {CustomNavElement && <CustomNavElement />}

      <Stack spacing={2} sx={{ p: 3, color: colors.primary.white, marginTop: '30px' }}>
        <SideNavDivider />
      </Stack>
      <Stack
        component='nav'
        spacing={1}
        sx={{
          flexGrow: 1,
          px: 2
        }}
      >
        {LanguagePicker}
        {navItems.map((section, index) => (
          <SideNavSection items={section?.items} key={index} />
        ))}
      </Stack>
    </Stack>
  );
};

export default SideNavFooter;
